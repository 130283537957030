import { ActionReducer, ActionReducerMap, INIT, MetaReducer } from '@ngrx/store';
import * as fromLoading from './reducers/loading.reducer';
import * as fromJurists from './reducers/jurists.reducer';
import * as fromIntegrationSettings from './reducers/integration-settings.reducer';
import * as fromTickets from './reducers/tickets.reducer';
import * as fromPublic from './reducers/public.reducer';

export interface VNSBackofficeState {
  loading: fromLoading.LoadingState;
  integrationSettings: fromIntegrationSettings.SubscriptionSettingsState;
  tickets: fromTickets.TicketsState;
  jurists: fromJurists.JuristsState;
  public: fromPublic.PublicState;
}

export const ROOT_REDUCER: ActionReducerMap<VNSBackofficeState> = {
  loading: fromLoading.reducer,
  integrationSettings: fromIntegrationSettings.reducer,
  tickets: fromTickets.reducer,
  jurists: fromJurists.reducer,
  public: fromPublic.reducer,
};

const log_prefix = '< Store >\n$';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const log = (...args: any): void => console.log(`${log_prefix}`, ...args);

export function debug(reducer: ActionReducer<unknown>): ActionReducer<unknown> {
  return function (state, action) {
    log('state', state);
    log('action', action);
    return reducer(state, action);
  };
}

export const hydrationMetaReducer = (
  reducer: ActionReducer<VNSBackofficeState>
): ActionReducer<VNSBackofficeState> => {
  return (state, action) => {
    if (action.type === INIT) {
      const storageValue = localStorage.getItem('state');
      if (storageValue !== null) {
        try {
          return JSON.parse(storageValue);
        } catch (err) {
          localStorage.removeItem('state');
        }
      }
    }
    const nextState = reducer(state, action);
    localStorage.setItem('state', JSON.stringify(nextState));
    return nextState;
  };
};

export const metaReducers: MetaReducer<VNSBackofficeState>[] = [hydrationMetaReducer];
