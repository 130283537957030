import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Ticket, TicketSummary, VNSEnvironment } from '@ca/vns-models';
import { AuthService, VNS_ENVIRONMENT } from '@ca/vns-ng-core';

@Injectable({
  providedIn: 'root',
})
export class TicketsService {
  constructor(
    @Inject(VNS_ENVIRONMENT) private env: VNSEnvironment,
    private http: HttpClient,
    private readonly auth: AuthService
  ) {}

  getTickets() {
    return this.http.get<TicketSummary[]>(`${this.env.backendUrl}/api/tickets`, {
      headers: this.auth.getAuthHeader(),
    });
  }

  getTicketDetail(id_or_metaUrl: string) {
    return this.http.get<Ticket>(`${this.env.backendUrl}/api/tickets/${id_or_metaUrl}`, {
      headers: this.auth.getAuthHeader(),
    });
  }
}
