import { Injectable } from '@angular/core';
import { SubscriptionSettingsService } from '../../services/subscription-settings.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  LoadSubscriptionSettingsResources,
  LoadSubscriptionSettingsResourcesFailure,
  LoadSubscriptionSettingsResourcesSuccess,
  LoadSubscriptionsSettings,
  LoadSubscriptionsSettingsFailure,
  LoadSubscriptionsSettingsSuccess,
  UpdateSubscriptionsSettings,
  UpdateSubscriptionsSettingsFailure,
  UpdateSubscriptionsSettingsSuccess,
} from '../reducers/integration-settings.reducer';
import { firstValueFrom, switchMap } from 'rxjs';

@Injectable()
export class SubscriptionSettingsEffects {
  constructor(
    private readonly svc: SubscriptionSettingsService,
    private readonly actions$: Actions
  ) {}

  onLoadSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadSubscriptionsSettings),
      switchMap(() =>
        firstValueFrom(this.svc.read())
          .then((res) => LoadSubscriptionsSettingsSuccess(res))
          .catch((err) => LoadSubscriptionsSettingsFailure({ error: err }))
      )
    )
  );

  onLoadSettingsResources$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadSubscriptionSettingsResources),
      switchMap(() =>
        firstValueFrom(this.svc.fetchTMLResources())
          .then((res) => LoadSubscriptionSettingsResourcesSuccess(res))
          .catch((err) => LoadSubscriptionSettingsResourcesFailure({ error: err }))
      )
    )
  );

  onUpdateSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateSubscriptionsSettings),
      switchMap((dto) =>
        firstValueFrom(this.svc.update(dto))
          .then((res) => {
            console.log('success', res);
            return UpdateSubscriptionsSettingsSuccess(dto);
          })
          .catch((err) => UpdateSubscriptionsSettingsFailure({ error: err }))
      )
    )
  );
}
