import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { AuthService, VNS_ENVIRONMENT } from '@ca/vns-ng-core';
import { AdminDTO, JuristDetailDTO, RegisterJuristDto, VNSEnvironment } from '@ca/vns-models';

@Injectable({
  providedIn: 'root',
})
export class JuristsService {
  constructor(
    @Inject(VNS_ENVIRONMENT) private env: VNSEnvironment,
    private http: HttpClient,
    private auth: AuthService
  ) {}

  getJurist(id: string) {
    return this.http.get<JuristDetailDTO>(`${this.env.backendUrl}/api/jurists/${id}`, {
      headers: this.auth.getAuthHeader(),
    });
  }
  getJurists() {
    return this.http.get<AdminDTO[]>(`${this.env.backendUrl}/api/jurists`, {
      headers: this.auth.getAuthHeader(),
    });
  }

  assignJurist(assignee_id: string, ticket_id: string) {
    return this.http.post(
      `${this.env.backendUrl}/api/tickets/${ticket_id}/assign`,
      {
        assignee_id,
      },
      {
        headers: this.auth.getAuthHeader(),
      }
    );
  }

  inviteJurist(email: string) {
    return this.http.post(
      `${this.env.backendUrl}/api/jurists/invitations.send`,
      {
        email,
      },
      {
        headers: this.auth.getAuthHeader(),
      }
    );
  }

  fetchInvitation(otp: string) {
    return this.http.get<{ oneTimePassword: string; email: string }>(
      `${this.env.backendUrl}/api/jurists/invitations/${otp}`
    );
  }

  completeRegistration(registerDto: RegisterJuristDto) {
    return this.http.post<{ message: string }>(
      `${this.env.backendUrl}/api/jurists/invitations/${registerDto.oneTimePassword}`,
      registerDto
    );
  }
}
