import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { CaSubscriber } from '@ca/ca-utils';
import { AdminDTO } from '@ca/vns-models';
import { FEATURE_KEY, Logout, SocketsService, UserState } from '@ca/vns-ng-core';

@Component({
  selector: 'ca-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'vns-backoffice';
  user?: AdminDTO;
  isLoggedIn = false;
  private sub = new CaSubscriber();

  constructor(
    private store: Store<{ [FEATURE_KEY]: { user: UserState } }>,
    private ws: SocketsService
  ) {}

  ngOnInit(): void {
    this.sub.subscribe(
      this.store.select((s) => s[FEATURE_KEY].user),
      {
        next: (s: UserState) => {
          this.user = s.user as AdminDTO | undefined;
          this.isLoggedIn = s.isLoggedIn;
        },
      }
    );
  }

  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }
  logout() {
    this.store.dispatch(Logout());
  }
}
