<mat-toolbar>
  <a *ngIf="user" mat-icon-button [routerLink]="['/']">
    <mat-icon>home</mat-icon>
  </a>
  <a *ngIf="!user" mat-icon-button [routerLink]="['/login']">
    <mat-icon>login</mat-icon>
  </a>

  <span>V-NS Backoffice</span>
  <span class="spacer"></span>

  <a mat-button *ngIf="user" [routerLink]="['/dossiers']">Dossiers</a>

  <a mat-button *ngIf="user && user.role === 'admin'" [routerLink]="['/juristen']">Juristen</a>

  <ng-container *ngIf="user">
    <a [matMenuTriggerFor]="settingsMenu">Mijn V-NS</a>
    <mat-menu #settingsMenu>
      <a mat-menu-item *ngIf="user.role === 'admin'" [routerLink]="['/instellingen']">
        Instellingen
      </a>
      <button mat-menu-item (click)="logout()">Afmelden</button>
    </mat-menu>
  </ng-container>
</mat-toolbar>
<div class="page">
  <router-outlet></router-outlet>
</div>
