import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CaSubscriber } from '@ca/ca-utils';
import { AdminDTO, Ticket } from '@ca/vns-models';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { AssignJurist, LoadTicketDetail, TicketsState } from '../../store/reducers/tickets.reducer';

import { ActivatedRoute } from '@angular/router';
import { ConversationComponent } from '@ca/chat-ui';
import { FEATURE_KEY, SocketsService, UserState } from '@ca/vns-ng-core';
import { JuristsState, LoadJurists } from '../../store/reducers/jurists.reducer';

@Component({
  selector: 'ca-ticket-detail',
  templateUrl: './ticket-detail.component.html',
  styleUrls: ['./ticket-detail.component.scss'],
})
export class TicketDetailComponent implements OnInit, OnDestroy {
  @ViewChild(ConversationComponent) chat!: ConversationComponent;
  sub = new CaSubscriber();
  user: AdminDTO | undefined;
  ticket: Ticket | undefined;
  jurists?: AdminDTO[];
  error?: string;

  constructor(
    private ws: SocketsService,
    private store: Store<{
      [FEATURE_KEY]: { user: UserState };
      tickets: TicketsState;
      jurists: JuristsState;
    }>,
    private route: ActivatedRoute
  ) {}

  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }

  ngOnInit(): void {
    this.sub.subscribe(this.route.params, {
      next: (params) => {
        console.log('route params', params);
        const id = params['id'];
        if (!id) this.error = 'Geen dossier gevonden.';
        this.store.dispatch(LoadTicketDetail({ id }));
      },
    });

    this.sub.subscribe(
      this.store.select((s) => s.jurists.jurists),
      {
        next: (jurists) => (this.jurists = jurists),
      }
    );

    this.sub.subscribe(
      this.store.select((s) => s[FEATURE_KEY].user.user),
      {
        next: (user) => {
          this.user = user as AdminDTO | undefined;
          if (this.user?.role === 'admin') {
            this.store.dispatch(LoadJurists());
          }
        },
      }
    );

    this.sub.subscribe(
      this.store.select((s) => s.tickets.detail),
      {
        next: (ticket) => {
          this.ticket = ticket;
          setTimeout(() => {
            if (!this.chat) throw new Error('Chat UI is not defined');
            if (this.chat.messages.length === 0 && this.ticket?.messages)
              this.chat.messages.push(...this.ticket.messages);
          }, 300);
        },
      }
    );
  }

  sendMessage(e: { message: string }) {
    const user = this.user;
    if (!user) throw new Error('User not found');
    const ticket_id = this.ticket?.id;
    if (!ticket_id) throw new Error('Ticket not found');
    console.log('sending message', e.message);
    this.ws.sendMessage({
      sent_by_id: user.id,
      sent_by_type: user.role,
      message: e.message,
      sent_by_name: user.first_name,
      sent_at: moment().toISOString(),
      ticket_id,
    });
  }

  assignJurist(e: string) {
    console.log('intent to assign jurist', e);
    const ticket_id = this.ticket?.id;
    if (!ticket_id) throw new Error('Ticket ID not known..');
    this.store.dispatch(
      AssignJurist({
        ticket_id,
        assignee_id: e,
      })
    );
  }
}
