import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { CaSubscriber } from '@ca/ca-utils';
import { AdminDTO, TicketSummary } from '@ca/vns-models';
import { Store } from '@ngrx/store';

import { LoadTickets, TicketsState } from '../../store/reducers/tickets.reducer';
import { MatTableDataSource } from '@angular/material/table';
import { FEATURE_KEY, UserState } from '@ca/vns-ng-core';
import { BehaviorSubject, combineLatest, filter, map, Observable, of, switchMap } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'ca-tickets',
  // template: `<h1>Lopende dossiers</h1>
  //   <mat-chip-listbox [(ngModel)]="assignedFilter" (ngModelChange)="filterTickets($event)">
  //     <mat-chip-option value="assigned">Toegewezen</mat-chip-option>
  //     <mat-chip-option value="unassigned">Niet toegewezen</mat-chip-option>
  //   </mat-chip-listbox>

  //   <mat-nav-list *ngIf="filteredTickets$ | async as tickets$">
  //     <a
  //       class="files-list-item"
  //       mat-list-item
  //       *ngFor="let ticket of tickets$"
  //       [routerLink]="['/dossiers', ticket.id]">
  //       <ca-ticket-status-chip [status]="ticket.status"></ca-ticket-status-chip>
  //       <span class="title">{{ ticket.title }}</span> -
  //       <span class="requested_at">{{ ticket.requested_at | date }}</span>
  //     </a>
  //   </mat-nav-list> `,
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss'],
})
export class TicketsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  assignedFilter: null | 'assigned' | 'unassigned' = 'unassigned';
  sub = new CaSubscriber();
  user: AdminDTO | undefined;
  datasource = new MatTableDataSource<TicketSummary>();
  allTickets: TicketSummary[] = [];
  tickets: BehaviorSubject<TicketSummary[]> = new BehaviorSubject<TicketSummary[]>(this.allTickets);
  filteredTickets$: Observable<TicketSummary[]> = this.tickets.asObservable();

  protected readonly displayedColumns = [
    'title',
    'requested_at',
    'owner.name',
    'assignee.name',
    'status',
    'action',
  ];

  constructor(
    private store: Store<{ [FEATURE_KEY]: { user: UserState }; tickets: TicketsState }>
  ) {}

  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }

  ngOnInit(): void {
    this.store.dispatch(LoadTickets());
    this.sub.subscribe(
      this.store.select((s) => s[FEATURE_KEY].user),
      {
        next: (user) => {
          this.user = user.user as AdminDTO | undefined;
        },
      }
    );

    this.sub.subscribe(
      this.store.select((s) => s.tickets.tickets),
      {
        next: (tickets) => {
          this.datasource.data = tickets;
        },
      }
    );
  }
  ngAfterViewInit(): void {
    this.datasource.paginator = this.paginator;
    this.datasource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.datasource.filter = filterValue.trim().toLowerCase();

    if (this.datasource.paginator) {
      this.datasource.paginator.firstPage();
    }
  }
}
export type TicketsFilter = {};
