import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  LoadTicketDetail,
  LoadTicketDetailsFailure,
  LoadTicketDetailSuccess,
  LoadTickets,
  LoadTicketsFailure,
  LoadTicketsSuccess,
} from '../reducers/tickets.reducer';
import { firstValueFrom, switchMap } from 'rxjs';
import { TicketsService } from '../../services/tickets.service';

@Injectable()
export class TicketsEffects {
  constructor(private actions$: Actions, private tickets: TicketsService) {}
  onLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadTickets),
      switchMap(() =>
        firstValueFrom(this.tickets.getTickets())
          .then((res) => LoadTicketsSuccess({ tickets: res }))
          .catch((err) => LoadTicketsFailure({ error: err }))
      )
    )
  );
  onLoadDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadTicketDetail),
      switchMap(({ id }) =>
        firstValueFrom(this.tickets.getTicketDetail(id))
          .then((res) => LoadTicketDetailSuccess({ ticket: res }))
          .catch((err) => LoadTicketDetailsFailure({ error: err }))
      )
    )
  );
}
