import { Ticket, TicketSummary } from '@ca/vns-models';
import { createAction, createReducer, on, props } from '@ngrx/store';

export interface TicketsState {
  tickets: TicketSummary[];
  detail?: Ticket;
}
export enum TicketActions {
  loadTickets = '[Tickets] load',
  loadTicketsSuccess = '[Tickets] successfully loaded',
  loadTicketsFailure = '[Tickets] failed to load',

  loadTicketDetail = '[Ticket Detail] load',
  loadTicketDetailSuccess = '[Ticket Detail] successfully loaded',
  loadTicketDetailFailure = '[Ticket Detail] failed to load',

  assignJurist = '[Assign] Jurist to ticket',
  assignJuristSuccess = '[Assign] Assigned jurist to ticket',
  assignJuristFailure = '[Assign] Failed to assign jurist to ticket',
}

export const LoadTickets = createAction(TicketActions.loadTickets);
export const LoadTicketsSuccess = createAction(
  TicketActions.loadTicketsSuccess,
  props<{ tickets: TicketSummary[] }>()
);
export const LoadTicketsFailure = createAction(
  TicketActions.loadTicketsFailure,
  props<{ error: any }>()
);

export const LoadTicketDetail = createAction(
  TicketActions.loadTicketDetail,
  props<{ id: string }>()
);
export const LoadTicketDetailSuccess = createAction(
  TicketActions.loadTicketDetailSuccess,
  props<{ ticket: Ticket }>()
);
export const LoadTicketDetailsFailure = createAction(
  TicketActions.loadTicketDetailFailure,
  props<{ error: any }>()
);

export const AssignJurist = createAction(
  TicketActions.assignJurist,
  props<{ ticket_id: string; assignee_id: string }>()
);
export const AssignJuristSuccess = createAction(
  TicketActions.assignJuristSuccess,
  props<{ ticket_id: string; assignee_id: string }>()
);
export const AssignJuristFailure = createAction(
  TicketActions.assignJuristFailure,
  props<{ err: any }>()
);

const initialState: TicketsState = {
  tickets: [],
};

export const reducer = createReducer(
  initialState,
  on(LoadTicketsSuccess, (_, { tickets }) => ({
    ..._,
    tickets,
  })),
  on(LoadTicketDetailSuccess, (_, { ticket }) => ({
    ..._,
    detail: ticket,
  })),
  on(AssignJuristSuccess, (_, { assignee_id, ticket_id }) => ({
    ..._,
    detail:
      _.detail && _.detail.id === ticket_id
        ? {
            ..._.detail,
            assignee_id,
          }
        : _.detail,
  }))
);
