import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { JuristsState } from '../../store/reducers/jurists.reducer';
import { CaSubscriber } from '@ca/ca-utils';
import { AdminDTO } from '@ca/vns-models';
import { MatDialog } from '@angular/material/dialog';
import { InviteJuristComponent } from '../../pages/invite-jurist/invite-jurist.component';

@Component({
  selector: 'ca-jurists',
  templateUrl: './jurists.component.html',
  styleUrls: ['./jurists.component.scss'],
})
export class JuristsComponent implements OnInit, OnDestroy {
  private readonly sub = new CaSubscriber();
  protected jurists?: AdminDTO[];

  constructor(
    private store: Store<{
      jurists: JuristsState;
    }>,
    private dialog: MatDialog
  ) {}

  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }

  ngOnInit(): void {
    this.sub.subscribe(
      this.store.select((s) => s.jurists.jurists),
      {
        next: (jurists) => (this.jurists = jurists),
      }
    );
  }
  newJurist() {
    this.dialog.open(InviteJuristComponent);
  }
}
