<ng-container *ngIf="ticket">
  <a [routerLink]="['/dossiers']">Terug naar dossiers</a>
  <div class="details">
    <div class="header">
      <h1>{{ ticket.title }}</h1>

      <div *ngIf="user?.role === 'admin' && jurists" class="assignment">
        <mat-form-field>
          <mat-label>Toegewezen aan</mat-label>
          <mat-select [value]="ticket.assignee_id" (valueChange)="assignJurist($event)">
            <mat-option *ngFor="let j of jurists" [value]="j.id">
              {{ j.first_name }} {{ j.last_name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="member-info">
      <h2>Lid</h2>
      <p *ngIf="ticket.owner?.company_name">
        <mat-icon>domain</mat-icon> {{ ticket.owner.company_name }}
      </p>

      <p *ngIf="ticket.owner?.first_name && ticket.owner.last_name">
        <mat-icon>person</mat-icon> {{ ticket.owner.first_name }} {{ ticket.owner.last_name }}
      </p>

      <p *ngIf="ticket.owner?.active !== undefined">
        lidmaatschap is
        {{ ticket.owner.active ? 'actief, volgende vernieuwing op:' : 'inactief, loopt af op:' }}
        {{ ticket.owner.next_renewal_date | date }}
      </p>
    </div>
  </div>
</ng-container>

<ca-conversation [owner_id]="user?.id" (newMessage)="sendMessage($event)"></ca-conversation>

<div class="error" *ngIf="error">
  <p>{{ error }}</p>
  <a [routerLink]="['/dossiers']">Terug naar Dossiers</a>
</div>
