<div class="page">
  <mat-form-field>
    <mat-label>Zoeken</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="..." #input />
  </mat-form-field>

  <table *ngIf="datasource" class="tickets" mat-table [dataSource]="datasource" matSort>
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef>
        <button mat-icon-button>
          <mat-icon>more_horiz</mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let element">
        <a mat-icon-button [routerLink]="['/dossiers', element.id]"
          ><mat-icon>visibility</mat-icon></a
        >
      </td>
    </ng-container>

    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef>Vraag</th>
      <td mat-cell *matCellDef="let element">{{ element.title }}</td>
    </ng-container>

    <ng-container matColumnDef="requested_at">
      <th mat-header-cell *matHeaderCellDef>Aangevraagd op</th>
      <td mat-cell *matCellDef="let element">
        {{ element.requested_at | date : 'dd/MM/YY' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="assignee.name">
      <th mat-header-cell *matHeaderCellDef>Toegewezen aan</th>
      <td mat-cell *matCellDef="let element">
        {{ element.assignee?.first_name }} {{ element.assignee?.last_name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="owner.name">
      <th mat-header-cell *matHeaderCellDef>Naam Aanvrager</th>
      <td mat-cell *matCellDef="let element">{{ element.owner.company_name }}</td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let element">
        <ca-ticket-status-chip [status]="element.status"></ca-ticket-status-chip>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    pageSize="10"
    [pageSizeOptions]="[10, 25, 100]"
    aria-label="Select page of users"></mat-paginator>
</div>
