import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { JuristsState, LoadJurist, LoadJurists } from '../../store/reducers/jurists.reducer';
import { CaSubscriber } from '@ca/ca-utils';
import { JuristDetailDTO } from '@ca/vns-models';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ca-jurist-detail',
  templateUrl: './jurist-detail.component.html',
  styleUrls: ['./jurist-detail.component.scss'],
})
export class JuristDetailComponent implements OnInit, OnDestroy {
  private readonly sub = new CaSubscriber();
  protected jurist?: JuristDetailDTO;
  error?: string;

  constructor(
    private readonly route: ActivatedRoute,
    private store: Store<{ jurists: JuristsState }>
  ) {}

  ngOnInit(): void {
    this.sub.subscribe(this.route.params, {
      next: (params) => {
        console.log('route params', params);
        const id = params['id'];
        if (!id) this.error = 'Geen dossier gevonden.';
        this.store.dispatch(LoadJurist({ id }));
        // this.sub.subscribe(
        //   this.store.select((s) => s.jurists.jurists?.find((e) => e.id === id)),
        //   {
        //     next: (jurist) => {
        //       this.jurist = jurist;
        //     },
        //   }
        // );
        this.sub.subscribe(
          this.store.select((s) => s.jurists.jurist),
          {
            next: (jurist) => {
              this.jurist = jurist;
            },
          }
        );
      },
    });
  }

  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }
}
