import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { CaSubscriber } from '@ca/ca-utils';
import { Store } from '@ngrx/store';
import {
  CompleteRegistration,
  LoadInvitation,
  PublicState,
} from '../../store/reducers/public.reducer';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'ca-register-jurist',
  template: ` <div *ngIf="!fg && !error" class="loading">loading...</div>
    <div *ngIf="error">Géén uitnodiging gevonden...</div>
    <form *ngIf="fg" class="form register-jurist-form" [formGroup]="fg">
      <input hidden formControlName="oneTimePassword" />
      <mat-form-field>
        <mat-label>E-mail</mat-label>
        <input [disabled]="true" matInput type="text" formControlName="email" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Voornaam</mat-label>
        <input matInput type="text" formControlName="first_name" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Achternaam</mat-label>
        <input matInput type="text" formControlName="last_name" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Kies een wachtwoord</mat-label>
        <input matInput type="password" formControlName="password" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Herhaal wachtwoord</mat-label>
        <input matInput type="password" formControlName="password_confirmation" />
      </mat-form-field>
      <!-- TODO: recaptcha!! -->
      <div class="errors" *ngIf="submitted && fg.errors">
        <ng-container *ngIf="fg.errors['PasswordNoMatch']"
          >Wachtwoorden komen niet overeen</ng-container
        >
      </div>
      <button mat-button [disabled]="!fg.valid" (click)="completeRegistration()">
        Registratie afronden
      </button>
    </form>`,
})
export class RegisterJuristComponent implements OnInit, OnDestroy {
  private sub = new CaSubscriber();
  error?: string;
  submitted = false;
  constructor(
    private route: ActivatedRoute,
    private readonly store: Store<{ public: PublicState }>,
    private readonly fb: FormBuilder
  ) {}

  fg?: FormGroup;

  ngOnInit(): void {
    this.sub.subscribe(this.route.params, {
      next: (params: Params) => {
        const oneTimePassword = params['otp'];
        if (!oneTimePassword) this.error = 'géén key gevonden';
        this.store.dispatch(
          LoadInvitation({
            oneTimePassword,
          })
        );
      },
    });

    this.sub.subscribe(
      this.store.select((s) => s.public.invitation),
      {
        next: (invitation) => {
          // this.invitation = invitation;
          if (invitation) this.createFormGroup(invitation.oneTimePassword, invitation.email);
        },
      }
    );
  }
  private createFormGroup(oneTimePassword: string, email: string) {
    this.fg = this.fb.group(
      {
        oneTimePassword: this.fb.control(oneTimePassword, Validators.required),
        email: this.fb.control(email, [Validators.required, Validators.email]),
        first_name: this.fb.control(null, [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(191),
        ]),
        last_name: this.fb.control(null, [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(191),
        ]),
        password: this.fb.control(null, [Validators.required, Validators.minLength(8)]),
        password_confirmation: this.fb.control(null, [
          Validators.required,
          Validators.minLength(8),
        ]),
      },
      { validators: confirmPasswordValidator }
    );
  }

  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }

  completeRegistration() {
    if (this.fg && !this.submitted) {
      this.submitted = true;
      const registrationDto = {
        // hidden
        oneTimePassword: this.fg.controls['oneTimePassword'].value as string,
        email: this.fg.controls['email'].value as string,

        first_name: this.fg.controls['first_name'].value as string,
        last_name: this.fg.controls['last_name'].value as string,
        password: this.fg.controls['password'].value as string,
      };
      console.log('registering with dto', registrationDto);
      firstValueFrom(this.fg.valueChanges)
        .then(() => {
          this.submitted = false;
        })
        .catch((err) => {
          console.error(err);
          this.submitted = false;
        });
      this.store.dispatch(CompleteRegistration(registrationDto));
    }
  }
}
export const confirmPasswordValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  return control.value.password === control.value.password_confirmation
    ? null
    : { PasswordNoMatch: true };
};
