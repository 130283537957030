import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { InviteJurist } from '../../store/reducers/jurists.reducer';

@Component({
  selector: 'ca-invite-jurist',
  template: `
    <h1 mat-dialog-title>Nieuwe jurist uitnodigen</h1>
    <div mat-dialog-content class="container">
      <form class="form invite-jurist-form" *ngIf="fg" [formGroup]="fg">
        <mat-form-field>
          <mat-label>e-mail</mat-label>
          <input matInput type="email" formControlName="email" />
        </mat-form-field>
      </form>
      <div mat-dialog-actions align="end">
        <a mat-dialog-close class="cancel">Annuleer</a>

        <button mat-button [disabled]="!fg.valid" (click)="sendInvitation()">
          <mat-icon>send</mat-icon> Verstuur uitnodiging
        </button>
      </div>
    </div>
  `,
  styles: [
    `
      .container {
        width: 40vw;
        min-width: 312px;
        height: fit-content;
      }
    `,
  ],
})
export class InviteJuristComponent {
  constructor(
    private readonly store: Store,
    private readonly fb: FormBuilder,
    public dialogRef: MatDialogRef<InviteJuristComponent> // @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {}

  fg = this.fb.group({
    email: this.fb.control(null, [Validators.required, Validators.email]),
  });

  sendInvitation() {
    const email = this.fg.controls['email'].value;
    if (typeof email === 'string') {
      this.store.dispatch(
        InviteJurist({
          email,
        })
      );
      this.dialogRef.close();
    } else throw new Error('E-mail not valid!');
  }
}
