import { SubscriptionSettings, VNSSubscriptionSettingsTMLResourcesForSelect } from '@ca/vns-models';
import { createAction, createReducer, props, on } from '@ngrx/store';

export interface SubscriptionSettingsState {
  settingsData?: VNSSubscriptionSettingsTMLResourcesForSelect;
  settings?: SubscriptionSettings;
}

export const initialState: SubscriptionSettingsState = {};

export enum SubscriptionsSettingsAction {
  loadSubscriptionsSettings = '[Subscriptions Settings] Load',
  loadSubscriptionsSettingsSuccess = '[Subscriptions Settings] Loaded successfully',
  loadSubscriptionsSettingsFailure = '[Subscriptions Settings] Failed to load',

  loadSubscriptionsSettingsResources = '[Subscriptions Settings Resources] Load',
  loadSubscriptionsSettingsResourcesSuccess = '[Subscriptions Settings Resources] Loaded successfully',
  loadSubscriptionsSettingsResourcesFailure = '[Subscriptions Settings Resources] Failed to load',

  updateSubscriptionsSettings = '[Subscriptions Settings] Update',
  updateSubscriptionsSettingsSuccess = '[Subscriptions Settings] Updated successfully',
  updateSubscriptionsSettingsFailure = '[Subscriptions Settings] Failed to update',
}
// LOAD
export const LoadSubscriptionsSettings = createAction(
  SubscriptionsSettingsAction.loadSubscriptionsSettings
);
export const LoadSubscriptionsSettingsSuccess = createAction(
  SubscriptionsSettingsAction.loadSubscriptionsSettingsSuccess,
  props<SubscriptionSettings>()
);
export const LoadSubscriptionsSettingsFailure = createAction(
  SubscriptionsSettingsAction.loadSubscriptionsSettingsFailure,
  props<{ error: any }>()
);

// RESOURCES

export const LoadSubscriptionSettingsResources = createAction(
  SubscriptionsSettingsAction.loadSubscriptionsSettingsResources
);
export const LoadSubscriptionSettingsResourcesSuccess = createAction(
  SubscriptionsSettingsAction.loadSubscriptionsSettingsResourcesSuccess,
  props<VNSSubscriptionSettingsTMLResourcesForSelect>()
);
export const LoadSubscriptionSettingsResourcesFailure = createAction(
  SubscriptionsSettingsAction.loadSubscriptionsSettingsResourcesFailure,
  props<{ error: any }>()
);

// UPDATE
export const UpdateSubscriptionsSettings = createAction(
  SubscriptionsSettingsAction.updateSubscriptionsSettings,
  props<SubscriptionSettings>()
);
export const UpdateSubscriptionsSettingsSuccess = createAction(
  SubscriptionsSettingsAction.updateSubscriptionsSettingsSuccess,
  props<SubscriptionSettings>()
);
export const UpdateSubscriptionsSettingsFailure = createAction(
  SubscriptionsSettingsAction.updateSubscriptionsSettingsFailure,
  props<{ error: any }>()
);

export const reducer = createReducer(
  initialState,
  on(LoadSubscriptionsSettingsSuccess, (_, settings) => ({
    ..._,
    settings,
  })),
  on(LoadSubscriptionSettingsResourcesSuccess, (_, settingsData) => ({
    ..._,
    settingsData,
  }))
);
