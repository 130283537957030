import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  CompleteRegistration,
  completeRegistrationFailure,
  CompleteRegistrationSuccess,
  LoadInvitation,
  LoadInvitationFailure,
  LoadInvitationSuccess,
} from '../reducers/public.reducer';
import { firstValueFrom, switchMap } from 'rxjs';
import { JuristsService } from '../../services/jurists.service';

@Injectable()
export class PublicEffects {
  constructor(private actions$: Actions, private svc: JuristsService) {}
  onLoadInvitation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadInvitation),
      switchMap(({ oneTimePassword }) =>
        firstValueFrom(this.svc.fetchInvitation(oneTimePassword))
          .then((res) => LoadInvitationSuccess(res))
          .catch((err) => LoadInvitationFailure({ err }))
      )
    )
  );
  onCompleteRegistration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompleteRegistration),
      switchMap(({ email, first_name, last_name, oneTimePassword, password }) =>
        firstValueFrom(
          this.svc.completeRegistration({
            email,
            first_name,
            last_name,
            oneTimePassword,
            password,
          })
        )
          .then((res) => CompleteRegistrationSuccess({ message: res.message, email }))
          .catch((err) => completeRegistrationFailure({ err }))
      )
    )
  );
}
