import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CaSubscriber } from '@ca/ca-utils';
import {
  TML_ListDepartmentDTO,
  TML_ListMailTemplateDTO,
  TML_ListTaxRateDTO,
  TML_UserListDTO,
} from '@ca/tml-focus-core';
import { SubscriptionSettings, VNSSubscriptionSettingsTMLResourcesForSelect } from '@ca/vns-models';
import { Store } from '@ngrx/store';
import { VNSBackofficeState } from '../../store';
import {
  LoadSubscriptionSettingsResources,
  LoadSubscriptionsSettings,
  UpdateSubscriptionsSettings,
} from '../../store/reducers/integration-settings.reducer';

@Component({
  selector: 'ca-subscription-settings',
  templateUrl: './subscription-settings.component.html',
  styleUrls: ['./subscription-settings.component.scss'],
})
export class SubscriptionSettingsComponent implements OnInit, OnDestroy {
  private sub = new CaSubscriber();
  protected departments?: TML_ListDepartmentDTO[];
  protected taxRates?: TML_ListTaxRateDTO[];
  protected mailTemplates?: TML_ListMailTemplateDTO[];
  protected users?: TML_UserListDTO[];
  protected error?: {
    type: 'integration-settings-not-found' | 'settings-data-unreachable';
    error: string;
  };
  protected form = this.fb.group({
    tml_subscription_yearly_fee: this.fb.control<number | null>(null, [
      Validators.min(0),
      Validators.required,
    ]),
    tml_department_id: this.fb.control<string | null>(null, [Validators.required]),
    tml_subscription_tax_rate_id: this.fb.control<string | null>(null, Validators.required),
    tml_billing_days_in_advance: this.fb.control<number | null>(null, Validators.required),
    tml_payment_days_after_invoice_date: this.fb.control<number | null>(null, Validators.required),
    tml_subscription_document_template_id: this.fb.control<string | null>(
      null,
      Validators.required
    ),
    tml_default_responsible_user_id: this.fb.control<string | null>(null, Validators.required),
  });

  //#region Lifecycle
  constructor(
    private http: HttpClient,
    private fb: FormBuilder,
    private store: Store<VNSBackofficeState>
  ) {}

  ngOnInit(): void {
    this.store.dispatch(LoadSubscriptionSettingsResources());
    this.store.dispatch(LoadSubscriptionsSettings());

    this.sub.subscribe(
      this.store.select((s) => s.integrationSettings.settingsData),
      {
        next: (settingsData) => {
          if (settingsData) {
            console.log('fetched data', settingsData);
            this.departments = settingsData.tmlDepartments.data;
            this.taxRates = settingsData.tmlTaxRates.data;
            this.mailTemplates = settingsData.tmlMailTemplates.data;
            this.users = settingsData.tmlUsers.data;
          }
        },
        error: (err) => {
          console.error(err);
          this.error = {
            type: 'settings-data-unreachable',
            error:
              'Kon teamleader gegevens (departementen, btw tarieven, e-mail templates en gebruikers) niet ophalen van de server.',
          };
        },
      }
    );
    this.sub.subscribe(
      this.store.select((s) => s.integrationSettings.settings),
      {
        next: (data) => {
          console.log('found settings', data);
          this.form.patchValue({
            tml_billing_days_in_advance: data?.tml_billing_days_in_advance ?? null,
            tml_default_responsible_user_id: data?.tml_default_responsible_user_id ?? null,
            tml_department_id: data?.tml_department_id ?? null,
            tml_payment_days_after_invoice_date: data?.tml_payment_days_after_invoice_date ?? null,
            tml_subscription_document_template_id:
              data?.tml_subscription_document_template_id ?? null,
            tml_subscription_tax_rate_id: data?.tml_subscription_tax_rate_id ?? null,
            tml_subscription_yearly_fee: data?.tml_subscription_yearly_fee ?? null,
          });
          // TODO: patch form
        },
        error: (err) => {
          console.error(err);
          this.error = {
            type: 'integration-settings-not-found',
            error: 'Kon integratie settings niet ophalen',
          };
        },
      }
    );
  }

  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }
  //#endregion

  createDefault() {
    this.error = undefined;
    // use teamleader data in combination with defaults from config to create an initial config
  }

  submit() {
    console.log(this.form.value);
    const dto = this.form.value as SubscriptionSettings & { type: any };
    // update config
    this.store.dispatch(UpdateSubscriptionsSettings(dto));
  }
}
