import { AdminDTO, JuristDetailDTO } from '@ca/vns-models';
import { createAction, createReducer, on, props } from '@ngrx/store';

export interface JuristsState {
  jurists?: AdminDTO[];
  jurist?: JuristDetailDTO;
}

export const initialState: JuristsState = {};

export enum JuristActions {
  loadJurists = '[Jurists] Load',
  loadJuristsSuccess = '[Jurists] Loaded successfully',
  loadJuristsFailure = '[Jurists] Failed to load',

  loadJurist = '[Jurist] Load',
  loadJuristSuccess = '[Jurist] Loaded successfully',
  loadJuristFailure = '[Jurist] Failed to load',

  inviteJurist = '[Jurists] Invite',
  inviteJuristSuccess = '[Jurists] Invited successfully',
  inviteJuristFailure = '[Jurists] Failed to invite',
}
export const LoadJurists = createAction(JuristActions.loadJurists);
export const LoadJuristsSuccess = createAction(
  JuristActions.loadJuristsSuccess,
  props<{ jurists: AdminDTO[] }>()
);
export const LoadJuristsFailure = createAction(
  JuristActions.loadJuristsFailure,
  props<{ err: string }>()
);

export const LoadJurist = createAction(JuristActions.loadJurist, props<{ id: string }>());
export const LoadJuristSuccess = createAction(
  JuristActions.loadJuristSuccess,
  props<{ jurist: JuristDetailDTO }>()
);
export const LoadJuristFailure = createAction(
  JuristActions.loadJuristFailure,
  props<{ err: string }>()
);

export const InviteJurist = createAction(JuristActions.inviteJurist, props<{ email: string }>());
export const InviteJuristSuccess = createAction(
  JuristActions.inviteJuristSuccess,
  props<{ email: string }>()
);
export const inviteJuristFailure = createAction(
  JuristActions.inviteJuristFailure,
  props<{ err: any }>()
);

export const reducer = createReducer(
  initialState,
  on(LoadJuristsSuccess, (_, { jurists }) => ({
    ..._,
    jurists,
  })),
  on(LoadJuristSuccess, (_, { jurist }) => ({
    ..._,
    jurist,
  }))
);
