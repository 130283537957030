import { Route } from '@angular/router';
import { SubscriptionSettingsComponent } from './components/subscription-settings/subscription-settings.component';
import { TicketsComponent } from './components/tickets/tickets.component';

import { TicketDetailComponent } from './pages/ticket-detail/ticket-detail.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { AuthGuardService, LoginComponent } from '@ca/vns-ng-core';
import { JuristsComponent } from './components/jurists/jurists.component';
import { JuristDetailComponent } from './components/jurist-detail/jurist-detail.component';
import { ForbiddenComponent } from '@ca/vns-ng-core';
import { RegisterJuristComponent } from './pages/register-jurist/register-jurist.component';

export const appRoutes: Route[] = [
  {
    path: 'dossiers',
    children: [
      {
        path: '',
        component: TicketsComponent,
        pathMatch: 'full',
      },
      {
        path: ':id',
        component: TicketDetailComponent,
      },
    ],
    canActivate: [AuthGuardService],
  },

  {
    path: 'juristen',
    children: [
      {
        path: '',
        component: JuristsComponent,
        pathMatch: 'full',
      },
      {
        path: ':id',
        component: JuristDetailComponent,
      },
    ],
    canActivate: [AuthGuardService],
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'instellingen',
    canActivate: [AuthGuardService],
    component: SubscriptionSettingsComponent,
  },
  {
    path: 'aanmelden-als-jurist/:otp',
    component: RegisterJuristComponent,
  },
  // TODO: create general error page?
  {
    path: 'forbidden',
    component: ForbiddenComponent,
  },
  {
    path: '',
    redirectTo: 'dossiers',
    pathMatch: 'full',
  },
  { path: '**', component: PageNotFoundComponent },
];
