import { createAction, createReducer, on, props } from '@ngrx/store';
export interface PublicState {
  invitation?: {
    email: string;
    oneTimePassword: string;
  };
}
export enum PublicActions {
  completeRegistration = '[Jurist] complete registration',
  completeRegistrationSuccess = '[Jurist] completed registration successfully',
  completeRegistrationFailure = '[Jurist] failed to complete registration',

  loadInvitation = '[Jurist Invitation] load',
  loadInvitationSuccess = '[Jurist Invitation] loaded successfully',
  loadInvitationFailure = '[Jurist Invitation] failed to load',
}

export const CompleteRegistration = createAction(
  PublicActions.completeRegistration,
  props<{
    oneTimePassword: string;
    email: string;
    first_name: string;
    last_name: string;
    password: string;
  }>()
);
export const CompleteRegistrationSuccess = createAction(
  PublicActions.completeRegistrationSuccess,
  props<{
    email: string;
    message: string;
  }>()
);
export const completeRegistrationFailure = createAction(
  PublicActions.completeRegistrationFailure,
  props<{ err: any }>()
);

export const LoadInvitation = createAction(
  PublicActions.loadInvitation,
  props<{ oneTimePassword: string }>()
);
export const LoadInvitationSuccess = createAction(
  PublicActions.loadInvitationSuccess,
  props<{ oneTimePassword: string; email: string }>()
);
export const LoadInvitationFailure = createAction(
  PublicActions.loadInvitationFailure,
  props<{ err: any }>()
);

const initialState = {};
export const reducer = createReducer(
  initialState,
  on(LoadInvitationSuccess, (_, { email, oneTimePassword }) => ({
    ..._,
    invitation: {
      email,
      oneTimePassword,
    },
  }))
);
