<form *ngIf="!error" class="form settings-form" [formGroup]="form" (ngSubmit)="submit()">
  <h1>Instellingen voor abonnementen</h1>
  <!-- IntegrationSettings -->
  <mat-form-field>
    <!-- tml_subscription_yearly_fee -->
    <mat-label>Prijs lidmaatschap</mat-label>
    <input formControlName="tml_subscription_yearly_fee" type="number" matInput />
    <span matPrefix class="mat-form-field-suffix">€</span>
    <mat-hint>De jaarlijkse kost die wordt aangerekend voor een lidmaatschap.</mat-hint>
  </mat-form-field>

  <mat-form-field>
    <!-- TODO: SELECT met departments uit TML Focus -->
    <!-- tml_department_id -->
    <mat-label>Departement</mat-label>
    <mat-select formControlName="tml_department_id">
      <mat-option *ngFor="let dep of departments" [value]="dep.id"
        >{{ dep.name }} ({{ dep.status }})</mat-option
      >
    </mat-select>
    <mat-hint
      >Departement uit Teamleader Focus dat wordt gebruikt om abonnementen uit te geven aan
      leden.</mat-hint
    >
  </mat-form-field>

  <mat-form-field>
    <!-- TODO: select met taxRates uit tml focus -->
    <!-- tml_subscription_tax_rate_id -->
    <mat-label>BTW tarief voor abonnement</mat-label>
    <mat-select formControlName="tml_subscription_tax_rate_id">
      <mat-option *ngFor="let tr of taxRates" [value]="tr.id"
        >{{ tr.rate * 100 }}% - {{ tr.description }}</mat-option
      >
    </mat-select>
    <mat-hint>BTW tarief om toe te passen op abonnementen voor lidmaatschappen.</mat-hint>
  </mat-form-field>

  <mat-form-field>
    <!-- tml_billing_days_in_advance -->
    <mat-label>Factureren voor hernieuwingsdatum</mat-label>
    <!-- <input type="number" matInput /> -->
    <mat-select formControlName="tml_billing_days_in_advance">
      <mat-option [value]="0">0</mat-option>
      <mat-option [value]="7">7</mat-option>
      <mat-option [value]="14">14</mat-option>
      <mat-option [value]="21">21</mat-option>
      <mat-option [value]="28">28</mat-option>
    </mat-select>
    <span matSuffix class="mat-form-field-suffix">dagen</span>
    <mat-hint
      >Het aantal dagen voor de hernieuwingsdatum van het abonnement, dat de factuur moet worden
      verzonden?</mat-hint
    >
  </mat-form-field>

  <mat-form-field>
    <!-- tml_payment_days_after_invoice_date -->
    <mat-label>Betaaltermijn in dagen</mat-label>
    <input formControlName="tml_payment_days_after_invoice_date" type="number" matInput />
    <mat-hint
      >De betaaltermijn die een lid krijgt op zijn factuur en alvorens er eventuele herinneringen
      worden verstuurd vanuit Teamleader Focus.</mat-hint
    >
  </mat-form-field>

  <mat-form-field>
    <!-- tml_subscription_document_template_id -->
    <mat-label>E-Mail template voor facturen</mat-label>
    <mat-select formControlName="tml_subscription_document_template_id">
      <mat-option *ngFor="let mt of mailTemplates" [value]="mt.id"
        >{{ mt.name }} ({{ mt.language }})</mat-option
      >
    </mat-select>
    <mat-hint
      >De E-mail template die Teamleader Focus moet gebruiken bij het versturen van facturen uit
      abonnementen voor lidmaatschappen.</mat-hint
    >
  </mat-form-field>

  <mat-form-field>
    <!-- tml_default_responsible_user_id -->
    <mat-label>Gebruiker toewijzen aan nieuwe lidmaatschappen</mat-label>
    <mat-select formControlName="tml_default_responsible_user_id">
      <mat-option *ngFor="let u of users" [value]="u.id"
        >{{ u.first_name }} ({{ u.last_name }})</mat-option
      >
    </mat-select>
    <mat-hint
      >Deze gebruiker wordt in Teamleader Focus als verantwoordelijke aan elk nieuw abonnement voor
      een lidmaatschap gelinkt.</mat-hint
    >
  </mat-form-field>

  <ng-container *ngIf="form.errors">
    <pre>{{ form.errors | json }}</pre>
  </ng-container>
  <button mat-raised-button [disabled]="form.valid === false">Wijzigingen bewaren</button>
</form>

<div *ngIf="error" class="error">
  {{ error.error }}
  <ng-container *ngIf="error.type === 'integration-settings-not-found'">
    <button
      *ngIf="this.taxRates && this.departments && this.mailTemplates && this.users"
      mat-raised-button
      (click)="createDefault()">
      Default settings aanmaken
    </button>
  </ng-container>
</div>
