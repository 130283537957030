import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SubscriptionSettings, VNSSubscriptionSettingsTMLResourcesForSelect } from '@ca/vns-models';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionSettingsService {
  // TODO: move base url to env
  private readonly base = environment.backendUrl;
  constructor(private http: HttpClient) {}

  read() {
    return this.http.get<SubscriptionSettings>(
      `${this.base}api/registrations/integration-settings`
    );
  }

  update(data: SubscriptionSettings & { type: any }) {
    console.log('updating settings', data);
    const dto: SubscriptionSettings = {
      tml_billing_days_in_advance: data.tml_billing_days_in_advance,
      tml_default_responsible_user_id: data.tml_default_responsible_user_id,
      tml_department_id: data.tml_department_id,
      tml_payment_days_after_invoice_date: data.tml_payment_days_after_invoice_date,
      tml_subscription_document_template_id: data.tml_subscription_document_template_id,
      tml_subscription_tax_rate_id: data.tml_subscription_tax_rate_id,
      tml_subscription_yearly_fee: data.tml_subscription_yearly_fee,
    };
    return this.http.put(`${this.base}api/registrations/integration-settings`, dto);
  }

  fetchTMLResources() {
    return this.http.get<VNSSubscriptionSettingsTMLResourcesForSelect>(
      `${this.base}api/registrations/settings-data`
    );
  }
}
