//#region Modules
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CA_ENVIRONMENT, CaNgCoreModule } from '@ca/ca-ng-core';
import {
  UnauthorizedInterceptor,
  VNS_ENVIRONMENT,
  VNSCoreConfig,
  VnsNgCoreModule,
} from '@ca/vns-ng-core';

import {
  SnackbarModule,
  SnackbarModuleConfiguration,
  SnackbarServiceConfiguration,
} from '@ca/ca-snackbar';

import { ChatUiModule } from '@ca/chat-ui';
//#region MAT
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCommonModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatChipsModule } from '@angular/material/chips';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDialogModule } from '@angular/material/dialog';
//#endregion
//#endregion

//#region Services
import { SubscriptionSettingsService } from './services/subscription-settings.service';
import { TicketsService } from './services/tickets.service';
import { JuristsService } from './services/jurists.service';
//#endregion

//#region Config
import { appRoutes } from './app.routes';
import { environment } from '../environments/environment';
//#endregion

//#region NGRX
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ROOT_REDUCER, metaReducers } from './store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
//#region Effects
import { SubscriptionSettingsEffects } from './store/effects/subscription-settings.effects';
import { TicketsEffects } from './store/effects/tickets.effects';
import { AdminEffects } from './store/effects/admin.effects';
//#endregion
//#endregion

//#region Components
import { AppComponent } from './app.component';
import { SubscriptionSettingsComponent } from './components/subscription-settings/subscription-settings.component';
import { TicketsComponent } from './components/tickets/tickets.component';
import { TicketDetailComponent } from './pages/ticket-detail/ticket-detail.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { JuristsComponent } from './components/jurists/jurists.component';
import { JuristDetailComponent } from './components/jurist-detail/jurist-detail.component';
import { InviteJuristComponent } from './pages/invite-jurist/invite-jurist.component';
import { RegisterJuristComponent } from './pages/register-jurist/register-jurist.component';
import { PublicEffects } from './store/effects/public.effects';
//#endregion

@NgModule({
  declarations: [
    AppComponent,
    SubscriptionSettingsComponent,
    TicketsComponent,
    TicketDetailComponent,
    PageNotFoundComponent,
    JuristsComponent,
    JuristDetailComponent,

    InviteJuristComponent,
    RegisterJuristComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CommonModule,
    RouterModule.forRoot(appRoutes, { initialNavigation: 'enabledBlocking' }),
    VnsNgCoreModule.forRoot(new VNSCoreConfig('admin')),
    FormsModule,
    ReactiveFormsModule,
    CaNgCoreModule,
    SnackbarModule,
    // Material

    //#region MAT
    MatToolbarModule,
    MatButtonModule,
    MatCheckboxModule,
    MatCommonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatPaginatorModule,
    MatSelectModule,
    MatSortModule,
    MatTableModule,
    MatChipsModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatDialogModule,
    //#endregion

    StoreModule.forRoot(ROOT_REDUCER, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot([
      AdminEffects,
      SubscriptionSettingsEffects,
      TicketsEffects,
      PublicEffects,
    ]),
    StoreDevtoolsModule.instrument(),
    ChatUiModule,
  ],
  providers: [
    { provide: CA_ENVIRONMENT, useValue: environment },
    { provide: VNS_ENVIRONMENT, useValue: environment },
    { provide: HTTP_INTERCEPTORS, useClass: UnauthorizedInterceptor, multi: true },
    { provide: SnackbarModuleConfiguration, useValue: new SnackbarModuleConfiguration() },
    { provide: SnackbarServiceConfiguration, useValue: new SnackbarServiceConfiguration() },
    SubscriptionSettingsService,
    TicketsService,
    JuristsService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
