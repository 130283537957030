import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  InviteJurist,
  inviteJuristFailure,
  InviteJuristSuccess,
  LoadJurist,
  LoadJuristFailure,
  LoadJurists,
  LoadJuristsFailure,
  LoadJuristsSuccess,
  LoadJuristSuccess,
} from '../reducers/jurists.reducer';
import { firstValueFrom, switchMap } from 'rxjs';
import { JuristsService } from '../../services/jurists.service';
import {
  AssignJurist,
  AssignJuristFailure,
  AssignJuristSuccess,
} from '../reducers/tickets.reducer';
@Injectable()
export class AdminEffects {
  constructor(private actions$: Actions, private svc: JuristsService) {}

  onLoadJurists$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadJurists),
      switchMap(() =>
        firstValueFrom(this.svc.getJurists())
          .then((jurists) => LoadJuristsSuccess({ jurists }))
          .catch((err) =>
            LoadJuristsFailure({
              err,
            })
          )
      )
    )
  );

  onLoadJurist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadJurist),
      switchMap(({ id }) =>
        firstValueFrom(this.svc.getJurist(id))
          .then((jurist) =>
            LoadJuristSuccess({
              jurist,
            })
          )
          .catch((err) => LoadJuristFailure({ err }))
      )
    )
  );

  onAssignJurist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssignJurist),
      switchMap(({ assignee_id, ticket_id }) =>
        firstValueFrom(this.svc.assignJurist(assignee_id, ticket_id))
          .then((res) =>
            AssignJuristSuccess({
              ticket_id,
              assignee_id,
            })
          )
          .catch((err) => AssignJuristFailure({ err }))
      )
    )
  );

  onInviteJurist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InviteJurist),
      switchMap(({ email }) =>
        firstValueFrom(this.svc.inviteJurist(email))
          .then((res) => InviteJuristSuccess({ email }))
          .catch((err) => inviteJuristFailure({ err }))
      )
    )
  );
}
