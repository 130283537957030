<div *ngIf="jurist" class="jurist">
  <a [routerLink]="['/juristen']">Terug naar Juristen</a>
  <h1>{{ jurist.first_name }} {{ jurist.last_name }}</h1>
  <mat-slide-toggle [checked]="jurist.active">Actief</mat-slide-toggle>
  <!-- TODO: een lijst met uitnodigingen? -->
  <mat-nav-list *ngIf="jurist.tickets">
    <a *ngFor="let ticket of jurist.tickets" mat-list-item [routerLink]="['/dossiers', ticket.id]">
      <div matListItemTitle>
        <ca-ticket-status-chip
          class="list-item-chip"
          [status]="ticket.status"></ca-ticket-status-chip>
        {{ ticket.title }}
      </div>
      <div matListItemLine></div>
      <div matListItemLine>
        {{ ticket.requested_at | date : 'dd/MM/YY' }}
      </div>
    </a>
  </mat-nav-list>

  <pre>{{ jurist | json }}</pre>
</div>

<div class="error" *ngIf="error">
  <p>{{ error }}</p>
  <a [routerLink]="['/juristen']">Terug naar Juristen</a>
</div>
